<template>
  <div class="zi-main-container">
    <div
      class="zi-info-container"
      v-for="(item,index) in zhaoshangInfos"
      :data-id="item.id"
      :key="index"
      @click="clickInfo"
    >
      <img class="zi-info-img" :src="item.url" />
      <div class="zi-txt-container">
        <div class="zi-txt-title">{{item.title}}</div>
        <div class="zi-txt-introduce">{{item.contentDesc}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      zhaoshangInfos: []
    }
  },
  created () {
    this.$data.zhaoshangInfos = this.$store.getters.getZhaoshangItems
  },
  methods: {
    clickInfo (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      this.$router.replace({ name: 'ZiInvestmentMoreInfo', params: {ziIndex: temp } })
    }
  }
}
</script>

<style scoped>
.zi-main-container {
  display: flex;
  margin-top: 0.29rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.zi-info-container {
  width: 19.75rem;
  height: 19.75rem;
  display: flex;
  flex-direction: column;
  background: url("https://pc.hnchengyue.com/static/image/bigbackground/whitebg.png");
  background-size: 19.75rem 19.75rem;
}
.zi-info-container:hover{
  transform: scale(1.1,1.1);
  cursor: pointer;
}
.zi-info-img {
  width: 16.71rem;
  height: 8.85rem;
  margin-top: 1.45rem;
  margin-left: 1.49rem;
}
.zi-txt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 7.87rem;
}
.zi-txt-title {
  font-size: 0.85rem;
  color: #333333;
  letter-spacing: 1px;
}
.zi-txt-introduce {
  font-size: 0.71rem;
  color: #999999;
  letter-spacing: 1px;
}
</style>
